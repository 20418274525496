<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
  <div id="page">
    <Header :add_class="['header-auto-show']" />

    <div class="page-content pb-0">
      <div data-card-height="" class="card mb-0">
        <div class="content">
          <h4>Profil Usaha</h4>
          <div class="mt-5 mb-3">
            <!-- Form -->
            <p v-if="errors" class="color-red-dark mb-3">
              {{ errors.errorMsg }}
            </p>
            <div
              class="input-style no-borders no-icon validate-field mb-4"
              :class="{ 'input-style-active': data_profile.business_name }"
            >
              <input
                type="name"
                class="form-control validate-name"
                id="form1"
                v-model="data_profile.business_name"
                placeholder="Nama Usaha"
                required
              />
              <label for="form1">Nama usaha</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em
                v-if="!data_profile.business_name"
                :class="{
                  'color-red-dark font-800 req-val': errors.business_name,
                }"
                >(required)</em
              >
            </div>

            <div
              class="input-style no-borders no-icon validate-field mb-4"
              :class="{ 'input-style-active': data_profile.business_field }"
            >
              <input
                type="name"
                class="form-control validate-name"
                id="form1a"
                v-model="data_profile.business_field"
                placeholder="Bidang usaha"
                required
              />
              <label for="form1a">Bidang usaha</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em v-if="!data_profile.business_field">(required)</em>
            </div>

            <div
              class="input-style no-borders no-icon mb-4"
              :class="{ 'input-style-active': data_profile.headline }"
            >
              <textarea
                id="form1b"
                placeholder="Headline"
                v-model="data_profile.headline"
                required
                maxlength="500"
              ></textarea>
              <label for="form1b">Headline</label>
            </div>

            <div
              class="input-style no-borders no-icon mb-4"
              :class="{ 'input-style-active': data_profile.long_about }"
            >
              <textarea
                id="form1c"
                placeholder="Tentang perusahaan"
                v-model="data_profile.long_about"
                required
              ></textarea>
              <label for="form1c">About</label>
            </div>

            <div
              class="input-style no-borders no-icon mb-4"
              :class="{ 'input-style-active': data_profile.why_work_with_us }"
            >
              <textarea
                id="form1d"
                placeholder="Kenapa kerja dengan kami"
                v-model="data_profile.why_work_with_us"
                required
              ></textarea>
              <label for="form1d"> Kenapa kerja dengan kami</label>
            </div>

            <div
              class="input-style no-borders no-icon validate-field mb-4"
              :class="{ 'input-style-active': data_profile.contact_name }"
            >
              <input
                type="name"
                class="form-control validate-name"
                id="form1e"
                placeholder="Kontak"
                v-model="data_profile.contact_name"
                required
              />
              <label for="form1e">Kontak</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em
                v-if="!data_profile.contact_name"
                :class="{
                  'color-red-dark font-800 req-val': errors.contact_name,
                }"
                >(required)</em
              >
            </div>

            <div
              class="input-style no-borders no-icon validate-field mb-4"
              :class="{ 'input-style-active': data_profile.website }"
            >
              <input
                type="name"
                class="form-control validate-name"
                id="form1f"
                v-model="data_profile.website"
                placeholder="Website usaha"
                required
              />
              <label for="form1f">Website</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em v-if="!data_profile.website">(required)</em>
            </div>

            <div
              class="input-style no-borders no-icon validate-field mb-4"
              :class="{ 'input-style-active': data_profile.phone_number }"
            >
              <input
                type="name"
                class="form-control validate-name"
                id="form1f"
                v-model="data_profile.phone_number"
                placeholder="Nomor Telepon Kantor"
                required
              />
              <label for="form1f">Nomor Telepon</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em
                v-if="!data_profile.phone_number"
                :class="{
                  'color-red-dark font-800 req-val': errors.phone_number,
                }"
                >(required)</em
              >
            </div>

            <div
              class="input-style no-borders no-icon validate-field mb-4"
              :class="{ 'input-style-active': data_profile.email }"
            >
              <input
                type="email"
                class="form-control validate-name"
                id="form1g"
                v-model="data_profile.email"
                placeholder="Email perusahaan"
                required
              />
              <label for="form1g">Email</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em
                v-if="!data_profile.email"
                :class="{
                  'color-red-dark font-800 req-val': errors.email,
                }"
                >(required)</em
              >
            </div>

            <div
              class="
                input-style
                no-borders no-icon
                validate-field
                mb-4
                input-style-always-active
              "
              :class="{ 'input-style-active': data_profile.team_size }"
            >
              <select id="form1h" v-model="data_profile.team_size">
                <option value="default" disabled="">Jumlah tim</option>
                <option
                  v-for="size in master_data.team_size"
                  :key="size.id"
                  :value="size.id"
                >
                  {{ size.size }}
                </option>
              </select>
              <label for="form1h">Tim</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em v-if="!data_profile.team_size" class="me-3">(required)</em>
            </div>

            <div
              class="
                input-style
                no-borders no-icon
                validate-field
                mb-4
                input-style-always-active
              "
              :class="{ 'input-style-active': data_profile.country }"
            >
              <select id="form1i" v-model="data_profile.country">
                <option value="default" disabled="" selected="">Negara</option>
                <option
                  v-for="country in master_data.country"
                  :key="country.id"
                  :value="country.id"
                >
                  {{ country.name }}
                </option>
              </select>
              <label for="form1i">Negara</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!data_profile.country"
                class="me-3"
                :class="{ 'color-red-dark font-800 req-val': errors.country }"
                >(required)</em
              >
            </div>

            <div
              class="
                input-style
                no-borders no-icon
                validate-field
                mb-4
                input-style-always-active
              "
              :class="{ 'input-style-active': province }"
            >
              <select id="form1j" v-model="province">
                <option value="default" disabled="" selected="">
                  Provinsi
                </option>
                <option
                  v-for="province in master_data.province"
                  :key="province.id"
                  :value="province.id"
                >
                  {{ province.name }}
                </option>
              </select>
              <label for="form1h">Provinsi</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!province"
                class="me-3"
                :class="{ 'color-red-dark font-800 req-val': errors.province }"
                >(required)</em
              >
            </div>

            <div
              class="
                input-style
                no-borders no-icon
                validate-field
                mb-4
                input-style-always-active
              "
              :class="{ 'input-style-active': data_profile.city }"
            >
              <select id="form1k" v-model="data_profile.city">
                <option value="default" disabled="" selected="">Kota</option>
                <option v-for="city in cities" :key="city.id" :value="city.id">
                  {{ city.name }}
                </option>
              </select>
              <label for="form1k">Kota</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!data_profile.city"
                class="me-3"
                :class="{ 'color-red-dark font-800 req-val': errors.city }"
                >(required)</em
              >
            </div>

            <div
              class="
                input-style
                no-borders no-icon
                validate-field
                mb-4
                input-style-always-active
              "
              :class="{ 'input-style-active': userBadge }"
            >
              <select id="form1hiring" v-model="userBadge">
                <option value="0">Nonaktif</option>
                <option value="1">Aktif</option>
              </select>
              <label for="form1hiring">Badge Hiring?</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!data_profile.city"
                class="me-3"
                :class="{ 'color-red-dark font-800 req-val': errors.city }"
                >(required)</em
              >
            </div>
          </div>
          <div class="d-flex mb-3">
            <div class="pt-1">
              <h5
                data-data-trigger-switch="toggle-hide-company"
                class="font-600 font-14"
              >
                Sembunyikan Bisnis
                <p class="font-10 font-100 line-height-s">
                  *Nama bisnis akan di sembunyikan
                </p>
              </h5>
            </div>
            <div class="ms-auto me-3 pe-2">
              <div class="custom-control ios-switch small-switch">
                <input
                  type="checkbox"
                  class="ios-input"
                  id="toggle-hide-company"
                  v-model="data_profile.hide_company"
                />
                <label
                  class="custom-control-label"
                  for="toggle-hide-company"
                ></label>
              </div>
            </div>
          </div>
          <a
            @click.prevent="submitForm"
            href="#"
            class="
              btn btn-full btn-m
              gradient-highlight
              rounded-s
              font-13 font-600
              mt-4
            "
            >Simpan Perubahan</a
          >
          <router-link
            :to="{ name: 'Dashboard' }"
            href="#"
            class="
              btn btn-full btn-m
              bg-red-dark
              rounded-s
              font-13 font-600
              mt-4
            "
            >Batal</router-link
          >
        </div>
      </div>
    </div>
    <!-- applied modals -->
    <div
      id="saved"
      class="menu menu-box-modal rounded-m"
      data-menu-hide="1500"
      data-menu-width="250"
      data-menu-height="170"
    >
      <h1 class="text-center mt-3 pt-2">
        <i class="fa fa-check-circle scale-icon fa-3x color-green-dark"></i>
      </h1>
      <h3 class="text-center pt-2">Berhasil di Simpan</h3>
    </div>

    <div
      id="validate-required"
      class="menu menu-box-modal bg-blue-dark rounded-m"
      data-menu-height="310"
      data-menu-width="350"
      style="display: block; width: 350px; height: 310px"
    >
      <h1 class="text-center mt-4">
        <i
          class="
            fa fa-3x fa-times-circle
            scale-box
            color-white
            shadow-xl
            rounded-circle
          "
        ></i>
      </h1>
      <p class="boxed-text-l color-white opacity-70">
        Beberap field wajib diisi<br />
        pastikan yang bertanda merah sudah terisi
      </p>
      <a
        @click="menuClose"
        href="#"
        class="
          close-menu
          btn btn-m btn-center-l
          button-s
          shadow-l
          rounded-s
          text-uppercase
          font-600
          bg-white
          color-black
        "
        >Keluar</a
      >
    </div>
    <div @click="menuClose" class="menu-hider"></div>
    <!--End applied modals -->
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";
import Header from "@/components/Header.vue";
export default {
  name: "CompanyProfile",
  components: {
    Header,
  },
  inject: ["getMasterData"],
  data() {
    return {
      apiEndpoint: process.env.VUE_APP_ROOT_API_NODE,
      business_name: "",
      data_profile: {
        business_name: "",
        business_field: "",
        headline: "",
        long_about: "",
        why_work_with_us: "",
        contact_name: "",
        website: "",
        phone_number: "",
        email: "",
        logo: "",
        user: "",
        team_size: "",
        country: "",
        province: "",
        city: "",
        business_type: [],
        gallery: [],
        hide_company: false,
      },
      errors: [],
      master_data: {},
      api_url: "/api/v1/employer/company/profile/",
      method: "post",
      isLoading: true,
      province: "",
      cities: "",
      userBadge: 0
    };
  },
  props: {
    data: Object,
  },
  mounted() {
    init_template();
    this.master_data = this.$store.state.master_data;
    this.getData();
    this.getStatusBadge();
  },
  watch: {
    province() {
      this.cities = this.master_data.province.filter((item) => {
        return item.id === this.province;
      });
      this.cities = this.cities[0].city;
    },
  },
  methods: {
    menuOpen,
    menuClose,
    checkRequired() {
      this.errors = {};
      // check required field not null
      var dataInput = {
        business_name: this.data_profile.business_name,
        email: this.data_profile.email,
        phone_number: this.data_profile.phone_number,
        contact_name: this.data_profile.contact_name,
        country: this.data_profile.country,
        province: this.data_profile.province,
        city: this.data_profile.city,
      };

      for (const key in dataInput) {
        if (dataInput[key] === "") {
          this.errors[key] = "Empty Input";
          this.errors["errorMsg"] = "Beberapa form wajib di isi";
        }
      }
      if (this.errors.errorMsg) {
        this.menuOpen("validate-required");
      }
    },
    /* get company profile data*/
    async getData() {
      this.errors = [];
      await axios
        .get(this.api_url)
        .then((response) => {
          console.log("response : " + response.data);
          if (response.data.length === 1) {
            this.data_profile = response.data[0];
            console.log('data profile : ', this.data_profile)
            this.province = response.data[0].province;
            this.method = "patch";
          }
          this.isLoading = false;
          this.checkRequired();
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "response data : " + JSON.stringify(error.response.data)
            );
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log();
            console.log("error :" + JSON.stringify(error));
          }
        });
    },

    async getStatusBadge() {
      this.errors = [];
      await axios
        .get("user/get-badge", {
          baseURL: this.apiEndpoint,
        })
        .then(({ data }) => {
          this.userBadge = data.data.status_badge
        })
        .catch((error) => {
          this.errors = [];
          if (error.response) {
            console.log(
              "response data : " + JSON.stringify(error.response.data)
            );
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log("error :", JSON.stringify(error));
          }
        });
    },

    async updateStatusBadge() {
      this.errors = [];
      let status = false
      await axios
        .post("user/change-badge", {
          status_badge: this.userBadge
        },{
          baseURL: this.apiEndpoint,
        })
        .then(({ data }) => {
          status = true
        })
        .catch((error) => {
          this.errors = [];
          if (error.response) {
            console.log(
              "response data : " + JSON.stringify(error.response.data)
            );
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log("error :", JSON.stringify(error));
          }
          status = false
        });
      return status;
    },

    validateInput(dataInput) {
      // validate required field
      this.errors = {};
      for (const key in dataInput) {
        if (dataInput[key] === "") {
          this.errors[key] = "Empty Input";
          this.errors["errorMsg"] = "Beberapa form wajib di isi";
        }
      }
    },

    /*update data*/
    async submitForm() {
      const formData = {
        business_name: this.data_profile.business_name,
        business_field: this.data_profile.business_field,
        headline: this.data_profile.headline,
        long_about: this.data_profile.long_about,
        why_work_with_us: this.data_profile.why_work_with_us,
        contact_name: this.data_profile.contact_name,
        phone_number: this.data_profile.phone_number,
        email: this.data_profile.email,
        team_size: this.data_profile.team_size,
        country: this.data_profile.country,
        province: this.province,
        city: this.data_profile.city,
        website: this.data_profile.website,
        hide_company: this.data_profile.hide_company,
      };

      this.validateInput(formData);

      if (!Object.keys(this.errors).length) {
        var request_method = "";
        /*if update*/
        if (this.method === "patch") {
          var url = this.api_url + this.data_profile.id + "/";
          request_method = axios.patch(url, formData);
        } else if (this.method === "post") {
          /*create profile*/
          request_method = axios.post(this.api_url, formData);
        }

        await this.updateStatusBadge()

        await request_method
          .then((response) => {
            console.log(response);
            this.data_profile = response.data;
            this.menuOpen("saved");
            setTimeout(() => {
              this.$router.push({ name: "Dashboard" });
            }, 2000);
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors[property] = `${error.response.data[property]}`;
              }
              console.log(JSON.stringify(error.response.data));
            } else if (error.message) {
              this.errors["error"] = "Something went wrong. please try again";
              console.log(JSON.stringify(error));
            }
            window.scrollTo(0, 0);
          });
      }
    },
  },
};
</script>
